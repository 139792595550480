






















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { dataImportsStore } from '@/store'
import { ImportProgressData } from '@/store/modules/dataImports/types'

@Component({ name: 'Step3' })
export default class Step3 extends Vue {
  @Prop({ type: String, default: '', required: true }) readonly uid!: string

  /**
   * Get translation text object
   */
  get texts (): { mayCloseModal: string, title: string, caption: string } {
    const texts = {
      mayCloseModal: this.$t('modals.ImportTasks.step3.mayCloseModal').toString(),
      title: '',
      caption: '',
    }
    if (dataImportsStore.getters.importFinished(this.uid)) {
      texts.title = this.$t('modals.ImportTasks.step3.importFinished').toString()
      texts.caption = this.$t('modals.ImportTasks.step3.captionDone').toString()
    } else {
      texts.title = this.$t('modals.ImportTasks.step3.importStarted').toString()
      texts.caption = this.$t('modals.ImportTasks.step3.captionProcessing').toString()
    }
    return texts
  }

  /**
   * Get current import object
   */
  get currentImport () {
    return dataImportsStore.state.data[this.uid]
  }

  /**
   * Get an object containing the import status
   */
  get progress (): ImportProgressData {
    return dataImportsStore.getters.progressData(this.uid)
  }

  get errorMessages (): string[] {
    return this.currentImport?.errorMessages ?? []
  }

  /**
   * Get expansion object
   */
  get expansionItem (): { label: string, iconToggle: boolean, iconClass: string } {
    const numErrors = this.currentImport?.errorMessages?.length
    const hasErrors = numErrors && numErrors > 0
    return {
      label: hasErrors
        ? this.$t('modals.ImportTasks.step3.hasErrors', { numErrors }).toString()
        : this.$t('modals.ImportTasks.step3.noErrors').toString(),
      iconToggle: !hasErrors,
      iconClass: !hasErrors
        ? 'invisible'
        : '',
    }
  }
}
